import { createSlice } from "@reduxjs/toolkit";

const OfferlocationSlice = createSlice({
  name: "location",
  initialState: {
    offerPickupLocation: null,
    offerDropLocation: null,
  },
  reducers: {
    setOfferPickupLocation(state, action) {
      state.offerPickupLocation = action.payload;
    },
    setOfferDropLocation(state, action) {
      state.offerDropLocation = action.payload;
    },
  },
});

export const { setOfferPickupLocation, setOfferDropLocation } =
  OfferlocationSlice.actions;
export default OfferlocationSlice.reducer;
