import { createSlice } from "@reduxjs/toolkit";

const locationSlice = createSlice({
  name: "location",
  initialState: {
    pickupLocation: null,
    dropLocation: null,
  },
  reducers: {
    setPickupLocation(state, action) {
      state.pickupLocation = action.payload;
    },
    setDropLocation(state, action) {
      state.dropLocation = action.payload;
    },
  },
});

export const { setPickupLocation, setDropLocation } = locationSlice.actions;
export default locationSlice.reducer;
