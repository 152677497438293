// store.js
import { configureStore } from "@reduxjs/toolkit";
import locationSlice from "./component/redux/locationSlice";
import offerLocationSlice from "./component/redux/offerLocationSlice";
const store = configureStore({
  reducer: {
    location: locationSlice,
    offerLocation: offerLocationSlice,
  },
});

export default store;
